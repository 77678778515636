:root {
    --Darkolive: #808000;
    --olive: #a3a02b;
    --navy: #001f3f;
    --black: #111111;
    --white: #ffffff;
    --Redclay: #b7563d;
    --tan: #e9b35f;
    --burgundy: #7d4554;
    --grey: #c0c0c0;
}

.footerEmail {
    color: var(--olive);
}

.icons-con {
    justify-content: center;
    text-align: center;
    /* margin-bottom: 10%; */
}

.ficons li{
    display: inline;
}

@media only screen and (max-width: 1024px) {
    #footer {
        height: 50vh;
    }
}

/* surface pro 7 */
@media only screen and (max-width: 912px) {
    #footer {
        height: 60vh;
    }
}


@media only screen and (max-width: 820px) {

    #footer {
        height: 50vh;
        padding-bottom: 350px !important;
    }

    .footerEmail {
     margin-left: 30px;
    }
}

@media only screen and (max-width: 768px) {
    #footer {
        height: 30vh;
        padding-bottom: 350px !important;
    }
}

@media only screen and (max-width: 390px) {
    #footer {
        padding-top: 200px;
    }
}

@media only screen and (max-width: 375px) {
    #footer {
        padding-bottom: 100px !important;
    }
}