:root {
    --Darkolive: #808000;
    --olive: #a3a02b;
    --navy: #001f3f;
    --black: #111111;
    --white: #ffffff;
    --Redclay: #b7563d;
    --tan: #e9b35f;
    --burgundy: #7d4554;
    --grey: #c0c0c0;
}

.navbar-brand {
    font-size: 22px !important;
    font-weight: bolder;
    font-family: sans-serif;
    color: var(--Redclay) !important;
}


#navbarSupportedContent ul li a {
    font-size: 13px !important;
    color: var(--olive) !important;
}

#navbarSupportedContent ul li a span {
    color: var(--Redclay) !important;
}

#navbarSupportedContent ul li button {
    color: var(--olive) !important;
    border: 1px solid var(--Redclay) !important;
    border-radius: 3px !important;
}



/* Ipad air viewport  */

@media only screen and (max-width: 820px) {
    .navbar-brand {
        font-size: 18px !important;
    }
}



/* ipad mini */
@media only screen and (max-width: 768px) {
    .navbar-brand {
        font-size: 16px !important;
    }

    .navbar-toggler{
        width: 55px;
        height: 40px;
    }


}