:root {
    --Darkolive: #808000;
    --olive: #a3a02b;
    --navy: #001f3f;
    --black: #111111;
    --white: #ffffff;
    --Redclay: #b7563d;
    --tan: #e9b35f;
    --burgundy: #7d4554;
    --grey: #c0c0c0;
}

* {
    box-sizing: border-box;
}

#contact {
    padding-top: 250px;
}

.contact .tyv {
    color: var(--white);
}

.span {
    color: var(--olive);
}

.contact .detail {
    color: var(--grey);  
}

.contact button {
border: 1px solid var(--olive);
margin-top: 10px;
border-style:inset;
color: var(--Redclay);
padding: 5px 10px;
border-radius: 4px;
}

.contact button:hover {
    background-color: rgba(62, 122, 130, 0.4) !important;
    color: var(--tan);
}

.item {
  margin: 5px;
  margin-top: 10px;
}

#text-box {
     margin-top: 10px;
    border: 4px var(--olive);
    border-style:inset;
    border-radius: 6px;
    width: 450px;
    height: 150px;
}

.item {
    border-radius: 6px;
    border: 2px var(--olive);
    border-style:inset;   
    width: 360px;
    text-align: center;
}

.button {
    margin-top: 10px;
    border-style:inset;
    border-color: #a3a02b;
    border-radius: 6px;
    width: 80px;
    height: 35px;
}

.button:hover {
    background-color: rgba(62, 122, 130, 0.4) !important;
    color: var(--tan) !important;
}

.form-prompt {
    width: 100%;
    font-size: 30px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Ipad pro view */
@media only screen and (max-width: 1024px) {
    #contact {
        height: 60vh !important;
        /* margin-bottom: 300px !important; */
        Padding-bottom: 170px;
    }
}


/* Ipad air */
@media only screen and (max-width: 820px){
    #contact {
        padding-top: 230px !important;
        height: 90vh !important;
    }
    #text-box {
        width: 390px !important;
    }

    .item {
        width: 340px !important;
    }
}

/* Ipad mini */
@media only screen and (max-width: 768px) {
    #contact {
        padding-top: 350px !important;
        height: 100vh !important;
    }

    .main-forum {
        height: 50% !important;
    }
    #text-box {
        width: 365px !important;
    }
}

@media only screen and (max-width: 430px) {

    .item  {
     width: 270px !important;
    }

    .contact #text-box {
        width: 275px !important;
    }
    .button {
        margin-left: 50px !important;
    }

    .main-forum {
        margin-right: 50px !important;
    }

    .email-prompt {
        width: 250px !important;
        margin-left: 15px;
    }

   .contact button {
        margin-left: 60px;
    }

    .form h3  {
       margin-left: 40px;
    }
}

@media only screen and (max-width: 390px) {

    #contact {
        height: 110vh !important;
        padding-top: 170px !important;
    }

    .main-forum {
        margin-right: 75px !important;
    }

    .contact button {
        margin-left: 85px !important;
    }

    .button {
        margin-left: 85px !important;
    }

    .form-prompt {
        width: 215px;
        margin-right: 70px;
    }

    .contact .detail {
        font-size: 17px !important;
    }

    .tyv {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 375px) {
    #contact {
        height: 120vh !important;
        padding-top: 150px !important;
    }
}

@media only screen and (max-width: 360px) {
   #contact {
    padding-top: 120px !important;
    height: 70vh !important;
   } 
}