:root {
    --Darkolive: #808000;
    --olive: #a3a02b;
    --navy: #001f3f;
    --black: #111111;
    --white: #ffffff;
    --Redclay: #b7563d;
    --tan: #e9b35f;
    --burgundy: #7d4554;
    --grey: #c0c0c0;
}

.about-text h3 {
    color: var(--white)
}
.about-text {
    color: var(--grey);
    text-align: justify;
}


.about-text h3 span {
    color: var(--olive) !important;
    font-size: 14px;
}
.about .row {
    max-width: 92%;
}
.about .wrapper {
    margin-left: 100px;
}

.about_image {
    max-width: 60%;
}

img {
width: 400px !important;
height: 400px !important;
margin-right: 160px;
}

.technology span {
    color: var(--Darkolive);   
}

/* IPAD PRO */
@media only screen and (max-width: 1024px) {
    img {
        width: 300px !important;
        height: 300px !important;
        margin-left: 100px !important;
    }

    #about {
        padding-top: 30px !important;
        height: 70vh !important;
    }

    .about-p {
        font-size: 16px !important;
    }

    .about-text h3 {
       font-size: 30px !important;
       padding-bottom: 5px !important;
    }
}


/* Ipad Air */
@media only screen and (max-width: 820PX) {

    img {
        width: 250px !important;
        height: 250px !important;
        margin-left: 100px !important;
    }

    .about-p {
        font-size: 15px !important;
    }


}

/* Ipad mini */
@media only screen and (max-width: 768px) {
    #about {
        height: 90vh !important;
    }
}



/* IPHONE 14 MAX */
@media only screen and (max-width: 430px) {
    #about {
        height: 100vh !important;
    }

    .about .wrapper {
        display: flex;
        align-items: flex-start;
        margin-left: 25px;
        width: 100% !important;
        }

        .about-text {
            position: relative;
            height: 110% !important;
       
        }

    .about-p {
        display: flex;
        text-align: center !important; 
        width: 100%;
    }

    .about-text h3 {
        font-size: 26px !important;
    }

    img {
        width: 200px !important;
        height: 200px !important;
        margin-right: 140px !important;
        padding-bottom: 60px;
    }
}

@media only screen and (max-width: 390px) {
    #about {
        height: 145vh !important;
    }

    .about-p {
        font-size: 17px !important;
    }

    .technology {
        font-size: 17px !important;
    }
}

@media only screen and (max-width: 375px) {
    #about {
        height: 180vh !important;
    }
}