:root {
    --Darkolive: #808000;
    --olive: #a3a02b;
    --navy: #001f3f;
    --black: #111111;
    --white: #ffffff;
    --Redclay: #b7563d;
    --tan: #e9b35f;
    --burgundy: #7d4554;
    --grey: #c0c0c0;
}

.projects h3 span {
    color: var(--olive) !important;
    font-size: 14px;
}

 a span {
    color: var(--white);
}

.link-con {
    margin-top: 10px !important;
    padding: 10px;
}
.project-link {
    text-decoration: none;
    color: var(--tan);
    
}
.card:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.card {
    transition: 2s;
}

.card-body {
    color: var(--grey);
}

.card-body button {
    padding: 3px 10px;
    font-size: 14px;
    color: var(--Redclay);
    border: 1px solid var(--olive);
    border-radius: 3px;
}

.card-body button:hover {
    background-color: rgba(62, 122, 130, 0.4) !important;
    color: var(--tan);
}

.card-img-top {
    width: 100% !important;
    height: 360px !important;
}

.card {
    height: 100% !important;
}

@media only screen and (max-width: 1199px) {
    .card-img-top {
        width: 260px !important;
        height: 240px !important;
    }

    .card {
        width: 265px !important;
        height: 100% !important;
    }
}

/* Ipad pro view */
@media only screen and (max-width: 1024px) {

 #projects {
        height: 65vh !important;
        padding-top: 30px !important;
        bottom: 10px !important;
    }
.card{
    width: 250px !important;
    height: 100% !important;
    box-sizing: border-box !important;
    }

 .card-img-top {
        position: relative !important;
        max-width: 240px !important;
        height: 200px !important;
        right: 95px !important;
    }
}

/* surface pro 7 */

@media only screen and (min-width: 821px) and (max-width: 912px) {


    #projects {
        padding-top: 50px !important;
    }

    .card{
        width: 200px !important;
        height: 100% !important;
        box-sizing: border-box !important;
        }
        .card-img-top {
            position: relative !important;
            max-width: 195px !important;
            height: 200px !important;
            right: 100px !important;
        }
}


/* Ipad air view */
@media only screen and (max-width: 820px ) {
    .card{
        width: 200px !important;
        height: 100% !important;
        box-sizing: border-box !important;
        }

        .card-img-top {
            position: relative !important;
            max-width: 195px !important;
            height: 200px !important;
            right: 100px !important;
        }

        #projects {
            height: 45vh !important;
            padding-top: 30px !important;
            bottom: 10px !important;
        }
}

@media only screen and (max-width: 430px) {
    .projects h3 {
        font-size: 26px !important;
    }

    .row {
        box-sizing: border-box;
        width: 100%;
        }

    #projects {
        height: 170vh !important;
    }
}

@media only screen and (max-width: 390px) {
    #projects {
        height: 235vh !important;
        padding-bottom: 100px !important;
    }
}

@media only screen and (max-width: 375px) {
    #projects {
        height: 315vh !important;
        /* padding-bottom: 100px !important; */
    }
}