:root {
    --Darkolive: #808000;
    --olive: #a3a02b;
    --navy: #001f3f;
    --black: #111111;
    --white: #ffffff;
    --Redclay: #b7563d;
    --tan: #e9b35f;
    --burgundy: #7d4554;
    --grey: #c0c0c0;
}

.experience h3 span {
    color: var(--olive);
    font-size: 14px;
}

.tabs_detail h5 a {
    color: var(--olive);
    text-decoration: none;
}

.tabs_detail p, .tabs_detail .date {
    color: var(--grey);
    font-size: 15px;
}

.experience .nav .nav-link {
    color: var(--grey) !important;
    font-size: 14px !important;
}

.experience .nav .active {
    background-color: var(--Redclay) !important ;
    color: var(--grey) !important;
    border-left: 2px solid var(--olive) !important;
    border-radius: 0px !important;
}


.experience .nav .active:hover {
    background-color: rgba(167, 186, 23, 0.6) !important;
}

@media only screen and (max-width: 1024px) {
    #experience {
        height: 80vh!important;
    }

    .experience h3{
      font-size: 30px !important;
    }

    p {
        font-size: 16px !important;
    }

}

/* Ipad air  */

@media only screen and (max-width: 820px) {

    p {
        font-size: 14px !important;
    }

    .experience h3{
        font-size: 27px !important;
      }

      #experience {
        height: 75vh!important;
    }
}

/* ipad mini */
@media only screen and (max-width: 768px) {
    #experience {
        height: 120vh !important;
    }
}

@media only screen and (max-width:430px) {
    #experience {
        height: 70vh;
        display: flex;
        justify-content: flex-start;
    }
    
    .experience h3 {
        font-size: 26px !important;
    }

    .nav-container {
        margin-right: 150px !important;
    }

    .tab-pane p {
        width: 200px !important;
    }
}

@media only screen and (max-width: 390px) {
    #experience h3 {
        margin-left: 45px;
    }

    .experience p {
        font-size: 16px !important;
    }

}

@media only screen and (max-width: 375px) {
    #experience {
        height: 150vh !important;
    }
}