:root {
  --Darkolive: #808000;
  --olive: #a3a02b;
  --navy: #001f3f;
  --black: #111111;
  --white: #ffffff;
  --Redclay: #b7563d;
  --tan: #e9b35f;
  --burgundy: #7d4554;
  --grey: #c0c0c0;
}

#home {
  max-width: 100%;
  overflow-x: hidden !important; 
}

#home::-webkit-scrollbar {
  display: none;
}

.icons-container {
  position: fixed;
  bottom: 0px;
}

.icons-container .icons {
  list-style-type: none;
}

.icons-container .icons li {
  margin: 5px 0px;
}

.icons-container .icons::after {
  content: "";
  display: block;
  width: 1px;
  height: 90px;
  margin-top: 10px;
  margin-left: 12px;
  background-color: var(--Darkolive);
}

.email-container {
  position: fixed;
  bottom: 30px;
  right: 15px;
}

.email-container p {
  color: var(--Redclay);
  writing-mode: vertical-lr;
  margin-right: 50px;
}

.email-container p::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 90px;
  margin-top: 10px;
  margin-right: 15px;
  background-color: var(--Darkolive);
}

main h1 {
  font-size: 4vw;
}

main {
  height: 100vh;
  /* position: relative;
  left: 120px; */
  margin-left: 120px;
}

main .hi-name {
  color: var(--olive);
  font-size: 2vw;
  font-weight: bold;
}

main h2 {
  color: var(--olive);
  font-size: 50px;
  /* font-size: 5vw; */
}

main .details {
  color: var(--grey);
  margin-top: 10px;
}

.details {
  font-size: 18px;
}

main button {
  display: inline-flex;
  margin-right: 15px;
  border: 2px solid var(--olive);
  padding: 5px;
  border-radius: 3px;
  margin-top: 10px;
  color: var(--olive);
}

main button:hover {
  background-color: rgba(62, 122, 130, 0.4) !important;
  color: var(--white) !important;
}

.learn {
  text-decoration: none;
  color: #b7563d;
}

#blog {
  text-decoration: none;
  color: #b7563d;
}

/* this hides css edits to smaller screens */

@media only screen and (max-width: 1148px) {
  .email-container {
  display: none !important;
}
.icons-container,
.email-container {
  display: none;
}
}

/* Ipad pro */

@media only screen and (max-width: 1024px) {
  main {
    height: 60vh !important;
  }

  .details {
    font-size: 20px !important;
    width: 99%;
  
  }

main .hi-name {
  font-size: 2vw !important;
}

main h1 {
  font-size: 5vw;
}

main h2 {
  font-size: 60px;
}

}


/* Ipad Air */
@media only screen and (max-width: 820px){

    main {
      margin-left: 100px !important;
      width: 80%;
      height: 60vh !important;
    }

    main h1 {
      font-size: 4vw;
    }

    main h2 {
      font-size: 45px;
    }

    .details {
      font-size: 16px !important; 
    }


}


/* Ipad mini */
@media only screen and (max-width: 768px) {
  .icons-container,
  .email-container {
    display: none;
  }

  main {
    padding-top: 50px;
  }

  #about {
    height: 100vh !important;
  }

  .details {
    font-size: 14px;
    margin-right: 40px;
  }
  main .hi-name {
    font-size: 1.5vw;
  }
  main h1 {
    font-size: 3vw;
  }
  main h2 {
    font-size: 35px;
  }
  .learn {
    font-size: 10px;
  }
  #blog {
    font-size: 10px;
  }
}


/* iphone max 14, XR,  pixel 7, S20 Ultra */
@media only screen and (max-width: 430px) {
  main {
    width: 67%;
    padding-right: 15px !important;
    height: 65vh !important;
  }

  #home::-webkit-scrollbar {
    display: none;
  }
    .details {
      font-size: 10px;
    }
    main .hi-name {
      font-size: 3.2vw !important;
    }
    main h1 {
      font-size: 4.3vw !important;
    }
    main h2 {
      font-size: 28px !important;
    }
    .learn {
      font-size: 15px;
    }
    #blog {
      font-size: 15px;
    }
  }

  @media only screen and (max-width: 390px) {
    #home::-webkit-scrollbar {
      display: none;
    }

    #home {
      height: 80vh;
    }

    main {
      width: 75%;
      padding-right: 10px !important;
      margin-left: 75px !important;
    }

    main .hi-name {
      font-size: 3.6vw !important;
    }

    main h1 {
      font-size: 4.7vw !important;
    }

    .details {
      font-size: 17px !important; 
    }

  }


  @media only screen and (max-width: 375px) {
    #home::-webkit-scrollbar {
      display: none;
    }

    #home {
      height: 110vh;
    }


  }